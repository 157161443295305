@import 'themes/default/less/theme-variables.less';

// Tạm thời chưa xài color đợi đồng nhất theme
.container {
  max-width: 1000px;
  margin: 0 auto;
}
.title {
  text-align: left;
  padding: 28px;
}
.title-left {
  text-align: center;
  padding: 8px 0;
}
.login {
  font-size: 16px;
  a {
    text-decoration: underline;
    color: @color-primary;
  }
}
.body {
  padding: 0;
  overflow: hidden;
  box-shadow: 0 0 1.25rem 0 rgb(0 0 0 / 20%);
  border-radius: 0.5rem;
  background-color: #fff;
  max-width: 95%;
  margin: 0 auto 24px auto;
}
.section {
  padding: 24px;
  background-color: #f8f8f8;
}
.main {
  background: url(../../../themes/default/bg-page-login.png);
  background-repeat: no-repeat !important;
  background-size: cover !important;
  min-height: 100vh;
}
.btn_get_all_exam {
  font-size: 16px;
  color: #ff4d4f;
}

@media (max-width: 768px) {
  .title {
    text-align: center;
    padding: 16px;
  }
  .section {
    padding: 16px;
  }
}
@media (max-width: 576px) {
  .title {
    text-align: center;
    padding: 20px 12px 8px 12px;
  }
  .section {
    padding: 8px;
  }
}

