// If you want to use import font from Google Font look like Roboto, use fonts.less
@import 'themes/default/less/fonts.less';
//If you want to use @font-face with custom font files, use fonts.css
@import 'themes/default/less/fonts.css';

@import 'antd/lib/style/themes/default.less';

// Overriding AntD theme variables
@import 'themes/default/less/theme-variables.less';
@import 'antd/dist/antd.less';

// Overrides AntD components
@import './overrides/drawer.less';
@import './overrides/menu.less';
@import './overrides/modal.less';
@import './overrides/button.less';
@import './overrides/input.less';
@import './styles.less';

// Custom css: font-family
@import 'themes/default/less/custom.less';

