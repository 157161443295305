.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  position: absolute !important;
  right: -14px !important;
  top: 2px !important;
  color: @color-danger !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)
  span.anticon {
  position: absolute !important;
  right: -28px !important;
  color: @text-primary-light !important;
}

.ant-form-item-label > label:not(.ant-form-item-required) span.anticon {
  position: absolute !important;
  right: -18px !important;
  color: @text-primary-light !important;
}
