.btn-secondary {
  .button-variant-primary(@grey-0, @secondary-color);
}

.btn-info {
  .button-variant-primary(@grey-0, @info-color);
}

.btn-warning {
  .button-variant-primary(@grey-0, @warning-color);
}

.btn-success {
  .button-variant-primary(@grey-0, @success-color);
}

.ant-btn-secondary {
  .button-variant-primary(@grey-0, @secondary-color);
}

.ant-btn-info {
  .button-variant-primary(@grey-0, @info-color);
}

.ant-btn-warning {
  .button-variant-primary(@grey-0, @warning-color);
}

.ant-btn-success {
  .button-variant-primary(@grey-0, @success-color);
}
