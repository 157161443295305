.container {
  box-shadow: 0px 4px 20px rgb(0 0 0 / 3%);
  border-radius: 8px;
  border: 1px solid #f5f5f5;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 8px;
}
.name {
  font-size: 16px;
  font-weight: 700;
  color: #262626;
  padding: 16px 24px;
  border-bottom: 1px solid #f5f5f5;
}
.body {
  padding: 16px 24px;
}
.childLeft {
  font-size: 14px;
  line-height: 21px;
  color: #8c8c8c;
}
.actions {
  border-top: 1px solid #f5f5f5;
  padding: 16px 24px;
}
.btnRegister {
  background-color: #fae8e9 !important;
  &:hover {
    background-color: #db3b46 !important;
    color: #ffffff !important;
  }
}
.modal_test_exam {
  font-size: 13px;
  min-height: 350px;
  overflow: auto;
  max-height: 550px;
  h5{
    font-size: 15px;
    font-weight: bold;
  }
  
}
.modal_test_exam_left {
  text-align: center;
  background-color: #fcf0f2;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  button {
    margin-top:14px
  }
  
}
.modal_test_exam_right {
  a{
    color: #0069c2;
  }
}

.modal_test_exam_box_result{
  background-color: #fcf0f2;
  border-radius: 20px;
}
.modal_test_exam_box_result_left{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  
  h4 {
    font-size: 15px;
    font-weight: bold;
  }
  h3 {
    font-weight: bold;
    color: red;
    margin-bottom: 0;
  }
}
.modal_test_exam_box_result_right{
  padding: 20px 15px 10px 0;
  p{
    font-size: 11px;
    margin-bottom: 0.5rem;
    span{
      font-weight: bold;
      font-size: 12px;
    }
  }
}
.form_test_code {
  margin-top: 20px;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .name {
    text-align: center;
    padding: 12px 16px;
  }
  .actions {
    padding: 12px 16px;
  }
}

